@import "./variables.scss";

// .nav {
//   color: black;
//   background-color: grey;
//   padding: 1em;
// }
.dropdown-menu {
  background: linear-gradient(rgba(33, 12, 12, 0.564), rgba(0, 0, 0, 0.619));
  border: none;
  // box-shadow: 6px 4px 7px 0px #58585830;
}

.dropdown-item{
  color: white;
  transition: $defaultTransition;
}
.dropdown-item:hover {
  background-color: rgba(220, 164, 255, 0.53);
  // color: white;
  // border-radius: 1em;
}

.navbar-toggler, .navbar-toggler:focus:not(:focus-visible) {
  border: none;
  outline: none;
}