@import "./variables.scss";

.front {
  display: flex;
}

.left {
  display: flex;
  flex: 1;
}

.homeimage {
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.homeintro {
  display: flex;
}
.homeInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
}

.right {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.row {
  justify-content: center;
}

.rightTop {
  flex: 1;
}

.rightBottom {
  flex: 0;
  width: 100%;
}

.kofi {
  border: 0px;
  height: 50px;
  transition: all .1s ease-in-out;

  &:hover {
    scale: 1.1;
  }
}

@media screen and (min-width:767px) and (max-width: 1023px) {
  .front {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .front {
    flex-direction: column;
  }
}
