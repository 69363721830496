.socialProfile {
  background: rgba(59, 11, 11, 0.267);
  transition: all 0.2s ease-in-out;
  border-radius: 1em;
  padding: 0.5em;
  margin: 1%;
}
.socialProfile a {
  text-decoration: none;
}
.socialProfile h2 {
  color: #fff2d6;
}
.socialProfile:hover {
  background: #e77828;
  transform: scale(1.1);
  box-shadow: 2px 2px 5px rgba(184, 27, 27, 0.329);
}

.col-sm-3 {
  width: 23%;
}

.profiles {
  margin-top: 2em;
}

.icon {
  border-radius: 0.5em;
}