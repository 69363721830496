.dropdown-menu {
  background: linear-gradient(rgba(33, 12, 12, 0.564), rgba(0, 0, 0, 0.619));
  border: none;
}

.dropdown-item {
  color: white;
  transition: all 0.2s ease-in-out;
}

.dropdown-item:hover {
  background-color: rgba(220, 164, 255, 0.53);
}

.navbar-toggler, .navbar-toggler:focus:not(:focus-visible) {
  border: none;
  outline: none;
}