.status {
  height: 2em;
  padding: 0.2em;
  border-radius: 0.5em;
  font-size: 2em;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.open::after {
  content: " open!";
}

div ~ .open {
  background-color: greenyellow;
  color: rgb(0, 80, 86);
}

.closed::after {
  content: " open!";
}

.closed::after .status {
  background-color: rgb(255, 47, 47);
  color: rgb(86, 0, 47);
}