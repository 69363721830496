@import "~bootstrap/scss/bootstrap";
@import "custom";
@import "./variables.scss";

* {
  color: $defaultTxtColor;
}

html, body {
  height: 100vh;
} 

body {
  margin: 0;
  background: $background;
  background-attachment: fixed;
}


.x {
  background-color: rgba(255, 236, 203, 0.315);
  padding: 2em;
  border-radius: .5em;
  margin-top: 2em;
}

.bg-dark {
  background-color: $navBackground;
}

.infoLink {
  text-decoration: none;
  transition: $defaultTransition;
  color: rgb(255, 203, 148);
  filter: saturate(2);

  &:hover {
    // color: $defaultTxtHoverColor;
    color: adjust-hue($color: #540000, $degrees: 3);
    // font-weight: bold;
    letter-spacing: 2;
  }
}