$defaultTransition: all .2s ease-in-out;

// Colors

$navBackground: rgba(255, 187, 147, 0.489);
// $background: linear-gradient(rgb(128, 0, 174), rgb(255, 134, 20));
$background: linear-gradient(rgb(48, 46, 49), rgb(255, 134, 20));

$defaultTxtColor: antiquewhite;
$defaultLinkColor: rgb(232, 205, 255);
$defaultTxtHoverColor: rgba(116, 36, 36, 0.622);